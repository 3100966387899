// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const aws_mobile = {
  aws_appsync_graphqlEndpoint:
    'https://yuhz6ow245ej3g27sr6pct3e6e.appsync-api.us-east-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  // 'aws_appsync_authenticationType': 'API_KEY',
  //'aws_appsync_apiKey': 'da2-ogkwzchi7fbc7bzjqwnerdifyi',
  aws_user_files_s3_bucket:
    'kiwichatappstack-kiwichatmsgimgsaa0ddb06-iyqycegv3bl0',
  aws_user_files_s3_bucket_region: 'us-east-2',
  aws_cognito_region: 'us-east-2',
  aws_user_pools_id: 'us-east-2_oiPTvgjZj',
  aws_user_pools_web_client_id: '2ijd3ckbnk0rqmo4405ms7hlok',
  aws_cognito_identity_pool_id:
    'us-east-2:20e777ec-1b65-4c29-b086-848d1fb559e9',
  API: {
    endpoints: [
      {
        name: 'accMgmtApiGateway',
        endpoint: 'https://test-acc-mgmt-api.kiwichat.com'
      },
      {
        name: 'documentApiGateway',
        endpoint: 'https://test-documents.kiwichat.com',
      },
    ],
  },
  Storage: {
    userPhotos: {
      bucket: 'kiwichatappstack-kiwichatuserphoto2406ec8a-jmzqi2lan76j',
      region: 'us-east-2',
    },
    messageImgs: {
      bucket: 'kiwichatappstack-kiwichatmsgimgsaa0ddb06-1e9ff7jxl2bc8',
      region: 'us-east-2',
    },
    grievanceAndForm: {
      bucket: 'kiwichatappstack-kiwichatgrievanceandformfilesed8-1oe7bmz3i36j8',
      region: 'us-east-2',
    },
    bulletins: {
      bucket: 'kiwichatappstack-kiwichatbulletinfilesfea9a418-639dgksouqey',
      region: 'us-east-2',
    },
    videoApp: {
      bucket: 'kiwichatappstack-kiwichatvideorecordings69c634a1-lhofiyx3b97m',
      region: 'us-east-2',
    },
    documents: {
      bucket: 'kiwichatappstack-kiwichatdocumentsde3420b0-1047r9l1nxvhx',
      region: 'us-east-2',
    },
  },
  logToSentry: true,
  environment: 'test', // dev | test | uat | production
  Sentry: {
    api_base_url: 'https://o1416691.ingest.sentry.io',
    project_id: '6760568',
    sentry_key: 'd9d3e94f72504f04a49f7ae9353c67c2',
  },

  AppInfo: {
    platform: 'WEB',
    platformVersion: '0.0.0',
    version: '0.0.0',
  },
  kiwi: {
    logLevel: 'DEBUG',
  },
  enviroment: {
    env: 'TEST',
  },
};

export default aws_mobile;
